<template>
  <div class="order-summary-container">
    <h3>Order Summary</h3>

    <!-- Loop through each seat's order in a fixed order -->
    <div v-for="seat in sortedOrders" :key="seat" class="seat-order">
      <div class="order-row">
        <!-- Column 1: Seat number -->
        <div class="seat-info">
          <h4>{{ seat }}</h4>
        </div>

        <!-- Column 2: Order details -->
        <div class="order-details">
          <ul v-if="hasItems(seatOrders[seat])">
            <li v-for="item in orderedItems(seatOrders[seat])" :key="item.name" class="order-item">
              <div v-if="item.quantity > 1">{{ item.quantity }} x {{ item.name }}</div>
              <div v-else>{{ item.name }}</div>
              <ul v-if="item.customizations && item.customizations.length" class="customization-list">
                <li v-for="customization in item.customizations" :key="customization" class="customization">
                  <i><b>{{ customization }}</b></i>
                </li>
              </ul>
            </li>
          </ul>
          <div v-else>No items ordered</div>
        </div>

        <!-- Show Complete button if order is submitted but not completed -->
        <div v-if="seatOrders[seat].submitted && !seatOrders[seat].completed">
          <button class="complete-button" @click="markComplete(seat)">Complete</button>
        </div>
      </div>
    </div>
  </div>

  <div class="master-summary">
  <!-- Snacks Section -->
  <div v-if="categorizedMasterSummary('Snacks').length">
    <h4>Snacks</h4>
    <ul>
      <li v-for="item in categorizedMasterSummary('Snacks')" :key="item.name">
        <div>{{ item.quantity }} x {{ item.name }}</div>
      </li>
    </ul>
  </div>

  <!-- Beverages Section -->
  <div v-if="categorizedMasterSummary('Beverages').length">
    <h4>Beverages</h4>
    <ul>
      <li v-for="item in categorizedMasterSummary('Beverages')" :key="item.name">
        <div>{{ item.quantity }} x {{ item.name }}</div>
      </li>
    </ul>
  </div>

  <!-- Alcoholic Beverages Section -->
  <div v-if="categorizedMasterSummary('AlcoholicBeverages').length">
    <h4>Alcoholic Beverages</h4>
    <ul>
      <li v-for="item in categorizedMasterSummary('AlcoholicBeverages')" :key="item.name">
        <div>{{ item.quantity }} x {{ item.name }}</div>
      </li>
    </ul>
  </div>

  <!-- Mini Bottles Section -->
  <div v-if="categorizedMasterSummary('MiniBottles').length">
    <h4>Mini Bottles</h4>
    <ul>
      <li v-for="item in categorizedMasterSummary('MiniBottles')" :key="item.name">
        <div>{{ item.quantity }} x {{ item.name }}</div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  props: ['orders'],
  data() {
    return {
      seatOrders: JSON.parse(JSON.stringify(this.orders)), // Local copy of orders
      masterSummary: { // Categorized total usage
        Snacks: [],
        Beverages: [],
        AlcoholicBeverages: [],
        MiniBottles: [],
      },
    };
  },
  computed: {
    sortedOrders() {
      const fixedSeatOrder = ['1A', '1C', '1D', '1F', '2A', '2C', '2D', '2F', '3A', '3C', '3D', '3F'];
      return fixedSeatOrder.filter((seat) => this.seatOrders[seat]);
    },
  },
  methods: {
    categorizedMasterSummary(category) {
      console.log('Category:', category, 'Items:', this.masterSummary[category]); // Debugging
      return this.masterSummary[category] || [];
    },
    hasItems(order) {
      return this.orderedItems(order).length > 0;
    },
    orderedItems(order) {
      const items = [
        ...this.processOrderItems(order?.snacks, 'Snacks', order),
        ...this.processOrderItems(order?.beverages, 'Beverages', order),
        ...this.processOrderItems(order?.alcohol, 'AlcoholicBeverages', order),
        ...this.processOrderItems(order?.miniBottles, 'MiniBottles', order),
      ];
      return items;
    },
    processOrderItems(items, category, order) {
      if (!items) return [];
      return Object.entries(items)
        .filter(([, quantity]) => quantity > 0)
        .map(([name, quantity]) => {
          const customizations = this.getCustomizations(name, order, category);
          return { name, quantity, customizations, category };
        });
    },
    getCustomizations(itemName, order, category) {
      const customizations = [];

      if (category === 'Beverages' && order?.customizations?.ice?.[itemName]) {
        customizations.push(order.customizations.ice[itemName]);
      }

      if (itemName === 'Hot Coffee' || itemName === 'Hot Tea') {
        if (order?.customizations?.cream?.[itemName]) {
          customizations.push(`${order.customizations.cream[itemName]} Cream`);
        }
        if (order?.customizations?.sugar?.[itemName]) {
          customizations.push(`${order.customizations.sugar[itemName]} Sugar`);
        }
        if (order?.customizations?.equal?.[itemName]) {
          customizations.push(`${order.customizations.equal[itemName]} Equal`);
        }
      }

      if (itemName === 'Hot Tea' && order?.customizations?.teaType?.[itemName]) {
        customizations.push(order.customizations.teaType[itemName]);
      }

      return customizations;
    },
    markComplete(seat) {
      this.$emit('markComplete', seat); // Emit to parent that order is complete

      const order = this.seatOrders[seat];
      if (order && order.items) {
        const categorizedItems = this.orderedItems(order);
        categorizedItems.forEach((item) => {
          if (this.masterSummary[item.category]) {
            const existingItem = this.masterSummary[item.category].find((i) => i.name === item.name);
            if (existingItem) {
              existingItem.quantity += item.quantity;
            } else {
              this.masterSummary[item.category].push({ ...item });
            }
          }
        });
      }

      console.log('Updated Master Summary:', this.masterSummary); // Debugging

      if (order) {
        order.completed = true;
      }
    },
  },
  watch: {
    orders: {
      deep: true,
      handler(newOrders) {
        this.seatOrders = JSON.parse(JSON.stringify(newOrders));
      },
    },
  },
};
</script>

<style scoped>
/* Order summary container */
.order-summary-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #ddd;
  font-family: 'Arial', sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

/* Alternating row background for clean separation */
.seat-order:nth-child(even) {
  background-color: #f2f2f2;
}

.seat-order:nth-child(odd) {
  background-color: #ffffff;
}

.seat-order {
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Flexbox row layout */
.order-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* Seat info */
.seat-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding-left: 20px;
  padding-right: 10px;
}

.seat-info h4 {
  margin: 0;
  font-size: 20px;
}

/* Order details column */
.order-details {
  text-align: left;
  padding-left: 20px;
  white-space: nowrap;
}

/* Styling for order items and customizations */
.order-details ul {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}

.order-item {
  font-size: 16px;
  margin-bottom: 5px;
}

.customization-list {
  padding-left: 15px;
}

.customization {
  font-style: italic;
  font-weight: bold;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

/* Add padding and margin to the Complete button */
.complete-button {
  padding: 10px 20px; /* Add padding inside the button */
  margin-left: 15px;  /* Add some space between the text and the button */
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.complete-button:hover {
  background-color: #45a049;
}

/* RESPONSIVENESS FOR IPHONE */
@media (max-width: 480px) {
  .order-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .seat-info {
    padding-left: 15px;
    padding-right: 10px;
  }

  .seat-info h4 {
    font-size: 16px;
  }

  .order-details {
    padding-left: 5px;
    font-size: 14px;
  }

  .order-item {
    font-size: 14px;
  }

  .seat-order {
    margin-bottom: 10px;
  }
}
</style>
