// src/itemsData.js
export default {
    snacks: {
      'Peanut M&Ms': 0,
      'Gardetto’s': 0,
      'Popcorn': 0,
      'Chips': 0,
      'Cinnamon Twists': 0,
      'Yogies': 0,
      'Waffle': 0,
      'Snack Box (premium)': 0,
      'Meat & Cheese Tray (premium)': 0,
    },
    beverages: {
      'Coke': 0,
      'Diet Coke': 0,
      'Coke Zero': 0,
      'Sprite': 0,
      'Ginger Ale': 0,
      'Spindrift': 0,
      'Seltzer Water': 0,
      'Tonic Water': 0,
      'Orange Juice': 0,
      'Cranberry Juice': 0,
      'Bloody Mary Mix': 0,
      'Cold Brew Coffee': 0,
      'Hot Coffee': 0,
      'Water': 0,
      'Hot Tea': 0,
    },
    alcohol: {
      'Sam Adams Lager': 0,
      'Michelob Ultra': 0,
      'Stella Artois': 0,
      'Sparkling Wine Brut': 0,
      'Cabernet Sauvignon Red Wine': 0,
      'Chardonnay White Wine': 0,
      'Sauvignon Blanc White Wine': 0,
      'High Noon': 0,
      'Old Fashioned': 0,
      'Margarita': 0,
    },
    miniBottles: {
      'Tanqueray Gin': 0,
      'Tito’s Vodka': 0,
      'Jack Daniel’s Whiskey': 0,
      'Buffalo Trace Bourbon': 0,
      'Bacardi Rum': 0,
      'Dewar’s Scotch': 0,
    },
    customizations: {
      ice: {},
      cream: {},
      sugar: {},
      equal: {},
      teaType: {},
    }
  };
  